import styled from "styled-components";
import mediaQuery from "../../../utils/mediaQuery";
import ExternalLink from "../ExternalLink";

const VoiceNumberContainer = styled.h2`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PhoneNumberLink = styled(ExternalLink)`
  text-decoration: none; /* Remove the underline */
`;

const PhoneNumberWrapper = styled.span`
  display: block;
  white-space: nowrap;

  @media (${mediaQuery.mediumLarge}) {
    display: inline-block;
  }
`;

const VoiceNumberInfo = () => {
  const phoneNumber = "(844) 787-0975";
  const strippedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ""); // filter out non-number chars

  return (
    <VoiceNumberContainer className="grv-text__heading--medium grv-weight--normal grv-margin__top--large-3">
      <span>
        <span className="grv-weight--semibold">Have questions about applying? </span>
        <PhoneNumberWrapper>
          Call us at <PhoneNumberLink href={`tel:+1${strippedPhoneNumber}`}>{phoneNumber}</PhoneNumberLink>
        </PhoneNumberWrapper>
      </span>
      <span>Monday-Friday 8 a.m.-8 p.m. ET.</span>
    </VoiceNumberContainer>
  );
};

export default VoiceNumberInfo;
