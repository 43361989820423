import styled from "styled-components";
import { CenteredRow, Col, Grid, Wrapper } from "../Layout";
import mediaQuery from "../../../utils/mediaQuery";
import FooterLinks from "./FooterLinks";
import FooterIcons from "./FooterIcons";
import FooterCopyright from "./FooterCopyright";
import CIPDisclaimer from "./CIPDisclaimer";
import VoiceNumberInfo from "./VoiceNumberInfo";
import useStore from "../../../store";

const FooterContainer = styled.div`
  flex: 1;
  background-color: var(--grv-color-digital-gray-5);
  width: 100%;
  height: 100%;
  padding-bottom: 28px;

  @media not (${mediaQuery.large}) {
    border-top: 1px solid var(--grv-color-digital-gray-15);
  }
`;

const FooterRow = styled(CenteredRow)`
  padding-top: 76px;

  @media print {
    display: none;
    background-color: var(--grv-color-white);
  }
`;

const Footer = ({ showCIPDisclaimer = false, hideVoiceNumber = false }) => {
  const voiceNumberEnabled =
    useStore(state => state.getTargetTestValue("voiceNumberTreatment")) === "voiceNumberEnabled";
  return (
    <FooterContainer>
      <Wrapper>
        <Grid>
          {!hideVoiceNumber && voiceNumberEnabled && (
            <CenteredRow>
              <Col lg={12}>
                <VoiceNumberInfo />
              </Col>
            </CenteredRow>
          )}
          {showCIPDisclaimer && (
            <CenteredRow>
              <Col lg={12}>
                <CIPDisclaimer />
              </Col>
            </CenteredRow>
          )}
          <FooterRow>
            <Col lg={2} md={2} sm={2} order={{ lg: 1, md: 1, sm: 2 }}>
              <FooterCopyright />
            </Col>
            <Col lg={8} md={4} sm={4} order={{ lg: 2, md: 2, sm: 1 }}>
              <FooterLinks />
            </Col>
            <Col lg={2} md={2} sm={2} order={{ lg: 3, md: 3, sm: 3 }}>
              <FooterIcons />
            </Col>
          </FooterRow>
        </Grid>
      </Wrapper>
    </FooterContainer>
  );
};

export default Footer;
