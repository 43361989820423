import styled from "styled-components";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import Button from "../../shared/Button";
import bankBranchImage from "../../../assets/BusinessBranchUnfilled.svg";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const StyledBusinessBranchImg = styled.img`
  height: 96px;
  aspect-ratio: 1;
  margin-bottom: var(--grv-size-spacing-medium-2);
`;

const SingleSignOnTemplate = ({ headerText, bodyText, buttonText, buttonLinkId, clickHandler }) => {
  const headingRef = useFocusHeading();
  return (
    <Page hidePromoBanner hideVoiceNumber>
      <Grid ref={headingRef}>
        <CenteredRow>
          <Col offset={{ lg: 2 }}>
            <StyledBusinessBranchImg alt="" src={bankBranchImage} />
          </Col>
        </CenteredRow>
        <Heading mainHeading={headerText} subHeading={bodyText} extraSpacingAfterMain />
        <CenteredRow>
          <Col offset={{ lg: 2 }}>
            <Button
              id={useButtonId(buttonLinkId)}
              onClick={clickHandler}
              gravityType="progressive"
              className="grv-margin__top--normal"
            >
              {buttonText}
            </Button>
          </Col>
        </CenteredRow>
      </Grid>
    </Page>
  );
};

export default SingleSignOnTemplate;
