import styled from "styled-components";
import { CenteredRow, Col, Grid } from "../Layout";
import Page, { ContentContainer } from "../Page";
import Heading from "../Heading/Heading";
import Button from "../Button";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import CEMPSurvey from "../CEMPSurvey";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const SM_ICON_SIZE = "87px";
const MD_ICON_SIZE = "96px";
const LG_ICON_SIZE = "128px";

const Icon = styled.img`
  height: ${({ $iconSize }) => $iconSize};
  aspect-ratio: 1;
`;

const handleClick = link => {
  window.open(link, "_blank");
};

const TerminalPageTemplate = ({
  image,
  imageAlt = "",
  smallIcon = false,
  mediumIcon = false,
  readContent,
  subHeading,
  enableButton = false,
  buttonId,
  buttonType = "action",
  buttonClassName = "grv-margin__top--medium-2",
  extraSpacingAfterMainHeading = true,
  showCEMPSurvey = false,
}) => {
  const headingRef = useFocusHeading();
  const scopedButtonId = useButtonId(enableButton ? buttonId : "");

  return (
    <Page wrapChildrenInContainer={false} hideVoiceNumber>
      <Grid ref={headingRef}>
        <ContentContainer>
          <CenteredRow>
            <Col offset={{ lg: 2 }}>
              <Icon
                alt={imageAlt}
                src={image}
                // smallIcon takes priority over mediumIcon
                // eslint-disable-next-line no-nested-ternary
                $iconSize={smallIcon ? SM_ICON_SIZE : mediumIcon ? MD_ICON_SIZE : LG_ICON_SIZE}
                className={smallIcon ? "grv-margin__bottom--medium-1" : "grv-margin__bottom--medium-2"}
              />
            </Col>
          </CenteredRow>
          <Heading
            mainHeadingProps={{
              dangerouslySetInnerHTML: { __html: readContent("headerText") },
            }}
            subHeadingProps={
              !subHeading && {
                dangerouslySetInnerHTML: { __html: readContent("bodyText") },
              }
            }
            subHeading={subHeading}
            extraSpacingAfterMain={extraSpacingAfterMainHeading}
          />
          {enableButton && (
            <CenteredRow>
              <Col offset={{ lg: 2 }}>
                <Button
                  id={scopedButtonId}
                  onClick={() => handleClick(readContent("buttonLinkTarget"))}
                  gravityType={buttonType}
                  className={buttonClassName}
                  role="link"
                >
                  {readContent("buttonText")}
                </Button>
              </Col>
            </CenteredRow>
          )}
        </ContentContainer>
        {showCEMPSurvey && <CEMPSurvey />}
      </Grid>
    </Page>
  );
};

export default TerminalPageTemplate;
