import styled, { css } from "styled-components";
import mediaQuery from "../../../../utils/mediaQuery";
import { CenteredRow, Col, Grid, Wrapper } from "../../../shared/Layout";
import combineStrings from "../../../../utils/combineStrings";

// Footnotes on the approval page are controlled via two counters and links with a specific aria-describedby attribute.
// Mostly this was done to minimize the degree to which both the promo code and the single account extra information
// components need to be aware of each other.
// This is loosely based on https://www.sitepoint.com/accessible-footnotes-css/
// and https://www.joshwcomeau.com/css/styling-ordered-lists-with-css-counters/
// This does assume that the promo code information is always displayed *before* the secondary product information,
// if both are present. If that ever changes, this will need to be reworked.

export const FOOTNOTE_SECTION_ID = "footnoteSectionLabel";

// This CSS is placed here for logical continuity, but actually needs to be embedded in whatever component
// contains the actual text that will have footnotes. For the successful approval page, this is the TitleElement component,
// but this may be needed in other versions of the page in the future, and for that reason it is kept separate.
export const footnoteLinkCSS = css`
  /* Establish a counter for each footnote link */
  counter-reset: footnote-links;

  /* Hide link styling on each footnote link and increment the counter */
  a[aria-describedby="${FOOTNOTE_SECTION_ID}"] {
    counter-increment: footnote-links;
    text-decoration: none;
    color: inherit;
    cursor: default;
    outline: none;
  }

  /* Display a superscript number after each footnote link */
  a[aria-describedby="${FOOTNOTE_SECTION_ID}"]::after {
    content: counter(footnote-links);
    vertical-align: super;
    padding-left: 0.125ch;
    font-size: 0.625em; /* 10px for the 16px standard text, but scales for the larger promo code details text */
  }
`;

const FootnoteList = styled.ol`
  /* Remove all spacing that comes from gravity, but return bottom margin on larger screens */
  padding-inline: 0;
  margin-block: 0;
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-large-2);
  }

  /* Disable default list styling, use our own counter so we can style it */
  list-style: none;
  counter-reset: footnotes;

  /* force any links that come from Chariot content to inherit these styles */
  a {
    color: inherit;
  }

  & > li {
    counter-increment: footnotes;

    /* 
      Precede each footnote with a superscript number from the counter.
      Note this assumes the order will be the same as the order of the links on the page!
    */
    &:before {
      content: counter(footnotes);
      padding-right: 2ch;
    }
  }

  /* Add padding between the list items */
  * + & > li {
    padding-top: var(--grv-size-spacing-medium-1);
  }
`;

const FootnotesSection = ({ className = "", displayPromotionCodeInformation, mobileDepositsExplanation }) => {
  if (!displayPromotionCodeInformation && !mobileDepositsExplanation) {
    // If there are no footnotes, return an empty fragment to avoid creating extra spacing
    // Moving this logic outside of the component would create duplication of these checks
    return <></>;
  }

  return (
    <div
      className={combineStrings(
        "grv-text--small-1",
        "grv-padding__top--none",
        "grv-color--digital-gray-70",
        className
      )}
    >
      <Wrapper>
        <Grid>
          <CenteredRow>
            <Col lg={12} className="grv-padding__left--none grv-padding__right--none">
              <h3 id={FOOTNOTE_SECTION_ID} hidden>
                Footnotes
              </h3>
              <FootnoteList>
                {displayPromotionCodeInformation && (
                  <li id="promoCodeInfo">
                    Activities required to be completed on your checking account in order to be eligible for
                    the cash bonus of $600 are:
                    <ul className="grv-padding__left--medium-3 grv-padding__right--none grv-margin__top--none grv-margin__bottom--none">
                      <li className="grv-padding__left--small-2">
                        Within 30 days of account opening, deposit at least $10,000 from non-Capital One
                        accounts. Transfers between Capital One accounts will not qualify
                      </li>
                      <li className="grv-padding__left--small-2">
                        Maintain a minimum end of day balance of $10,000 for at least 60 days within 90 days
                        of account opening
                      </li>
                      <li className="grv-padding__left--small-2">
                        Make 10 qualifying electronic transactions within 90 days of account opening.
                        Qualifying transactions include electronic wire (credits or debits), electronic ACH
                        (credits or debits), and remote check deposit
                      </li>
                    </ul>
                  </li>
                )}
                {mobileDepositsExplanation && (
                  <li
                    id="productSecondaryInfo"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: mobileDepositsExplanation }}
                  />
                )}
              </FootnoteList>
            </Col>
          </CenteredRow>
        </Grid>
      </Wrapper>
    </div>
  );
};

export default FootnotesSection;
