import { useEffect, useRef } from "react";
import shallow from "zustand/shallow";
import useStore from "../../store/store";
import {
  validMboxNames,
  validReceivableTargetTests,
  trackAdobeTargetEventForMbox,
} from "../adobeTargetHelpers";
import { sendErrorToNewRelic } from "../newRelic";
import useApplicationChannel from "./useApplicationChannel";

// Helper Functions
const getMboxesFromResponse = response => response.execute?.mboxes || [];

const findMatchingMbox = (mboxes, mboxName) => mboxes.find(mbox => mbox.name === mboxName);

const findMatchingContent = (mbox, targetTestName) =>
  mbox?.options
    ?.flatMap(option => option.content || [])
    .find(content => Object.prototype.hasOwnProperty.call(content, targetTestName));

const getReceivedTestValue = (response, mboxName, targetTestName) => {
  const mboxes = getMboxesFromResponse(response);
  const matchingMbox = findMatchingMbox(mboxes, mboxName);
  const matchingContent = findMatchingContent(matchingMbox, targetTestName);
  return matchingContent?.[targetTestName];
};

const parseAdobeTargetResponse = (
  response,
  addActiveAdobeTargetMbox,
  addTargetTestParam,
  setHasProcessedAdobeTarget,
  channel
) => {
  const finalABDecisions = {};

  // For each valid A/B test, we will check if the value received from Adobe Target is acceptable (and if we even received one in the first place).
  // If it is, we will use it. If not, we will use the default value set in the test's object in the validReceivableTargetTests array.
  validReceivableTargetTests.forEach(
    ({ targetTestName, mboxName, defaultValue, acceptableValues, excludedChannels }) => {
      const receivedTestValue = getReceivedTestValue(response, mboxName, targetTestName);

      const isChannelPermittedForTest = !excludedChannels.includes(channel);
      if (acceptableValues.includes(receivedTestValue) && isChannelPermittedForTest) {
        addActiveAdobeTargetMbox(mboxName);
        // Manually send an event to Adobe Target to indicate that we are using it for this A/B test
        trackAdobeTargetEventForMbox({ targetTestName: receivedTestValue }, mboxName);
        finalABDecisions[targetTestName] = receivedTestValue;
      } else {
        finalABDecisions[targetTestName] = defaultValue;
      }
    }
  );

  console.log("finalABDecisions: ", finalABDecisions);

  // Adds a valid value to the store for each validReceivableTargetTests item to be used as reference throughout application
  Object.entries(finalABDecisions).forEach(([key, value]) => addTargetTestParam(key, value));
  setHasProcessedAdobeTarget();
};

const fetchABTests = async () => {
  if (!window.adobe || !window.adobe.target) {
    console.log("Adobe target was not available");
    return {};
  }

  const offerRequest = {
    request: {
      execute: {
        mboxes: validMboxNames.map((name, index) => ({
          index,
          name,
        })),
      },
    },
  };

  try {
    return await window.adobe.target.getOffers(offerRequest);
  } catch (error) {
    console.error("Error fetching A/B tests from Adobe Target:", error);
    sendErrorToNewRelic(error);
    return {};
  }
};

const useLoadABTests = () => {
  // addTargetTestParam: function to add a target test parameter to the store
  // addActiveAdobeTargetMbox: function to set the store to indicate that we are using a valid mbox from Adobe Target for AB
  // setHasProcessedAdobeTarget: function to set the store to indicate that we have processed Adobe Target for A/B (even if we did not get a valid decision from it)
  const [addTargetTestParam, addActiveAdobeTargetMbox, setHasProcessedAdobeTarget] = useStore(
    state => [state.addTargetTestParam, state.addActiveAdobeTargetMbox, state.setHasProcessedAdobeTarget],
    shallow
  );
  const channel = useApplicationChannel();
  const isLoaded = useRef(false);

  useEffect(() => {
    const loadABTests = async () => {
      if (isLoaded.current) {
        return;
      }
      isLoaded.current = true;
      const adobeTargetResponse = await fetchABTests();
      parseAdobeTargetResponse(
        adobeTargetResponse,
        addActiveAdobeTargetMbox,
        addTargetTestParam,
        setHasProcessedAdobeTarget,
        channel
      );
    };

    // Only fetch content after we understand what channel we are in in order to determine if test is valid for channel
    if (channel !== null) {
      loadABTests();
    }
  }, [isLoaded, addTargetTestParam, addActiveAdobeTargetMbox, setHasProcessedAdobeTarget, channel]);
};

export default useLoadABTests;
