import { useFormContext } from "react-hook-form";
import { BooleanRadioListField } from "../../shared/RadioList";
import { Col } from "../../shared/Layout";
import Select from "../../shared/Select";
import {
  ANNUAL_JEWELRY_PURCHASE,
  ANNUAL_JEWELRY_SALES,
  CONDITIONAL_PARENTS,
  convertKeyIntoCamelCase,
  FIFTYK_TO_HUNDREDK,
  JEWELRY_NO_AML_PROGRAM,
  jewelryRevenueRangeDisplay,
  NO_ACTIVITIES,
  OVER_HUNDREDK,
  STANDARD_CONDITIONAL_RENDERS,
  ZERO_TO_FIFTYK,
} from "../../../constants/riskyBusinessQuestionsValues";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

const SubQuestionDropdown = ({ name, ...rest }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Col lg={4} md={3} sm={4} className="grv-padding--none">
      <Select
        id={useDropdownId(name)}
        className="grv-margin__top--small-2"
        error={errors?.[name]?.message}
        {...register(name)}
        {...rest}
      >
        <option className="grv-select__placeholder" value={NO_ACTIVITIES} disabled>
          Select a range
        </option>
        {[ZERO_TO_FIFTYK, FIFTYK_TO_HUNDREDK, OVER_HUNDREDK].map(range => (
          <option key={range} value={range}>
            {jewelryRevenueRangeDisplay[range]}
          </option>
        ))}
      </Select>
    </Col>
  );
};

const SubQuestionField = ({ subQuestionCategory, label }) => {
  const { control, setValue } = useFormContext();
  const name = `businessHas${convertKeyIntoCamelCase(subQuestionCategory)}`;

  const resetConditionalValues = parentValue => {
    if (Object.keys(CONDITIONAL_PARENTS).includes(parentValue)) {
      setValue(`businessHas${convertKeyIntoCamelCase(CONDITIONAL_PARENTS[parentValue])}`, null);
    }
  };

  if ([ANNUAL_JEWELRY_PURCHASE, ANNUAL_JEWELRY_SALES].includes(subQuestionCategory)) {
    // for businessHasAnnualJewelryPurchase and businessHasAnnualJewelrySales, we use a number range dropdown instead of yes or no radio
    return (
      <SubQuestionDropdown
        name={name}
        label={label}
        labelClassName="grv-text--medium-1"
        onBlur={e => {
          if (e.target.value === ZERO_TO_FIFTYK) {
            resetConditionalValues(subQuestionCategory);
          }
        }}
      />
    );
  }

  return (
    <BooleanRadioListField
      control={control}
      name={name}
      legendText={label}
      onChange={newValue => {
        if (!newValue) {
          // reset value
          setValue(`businessHas${convertKeyIntoCamelCase(subQuestionCategory)}`, NO_ACTIVITIES);
          resetConditionalValues(subQuestionCategory);
        }
      }}
    />
  );
};

const RiskyBusinessQuestions = ({ category, label, subQuestions = [] }) => {
  const { control, setValue, watch } = useFormContext();

  const mainCategoryKey = `businessHas${convertKeyIntoCamelCase(category)}`;
  // Variable watching the main question - if Yes is selected, subquestions will show up
  const mainCategoryIsSelected = watch(mainCategoryKey);
  const resetSubQuestionValues = () => {
    subQuestions.forEach(question => {
      setValue(`businessHas${convertKeyIntoCamelCase(Object.keys(question)[0])}`, null);
    });
  };

  const shouldRenderConditionalSubQuestion = conditionalCategory => {
    if (Object.keys(STANDARD_CONDITIONAL_RENDERS).includes(conditionalCategory)) {
      return watch(
        `businessHas${convertKeyIntoCamelCase(STANDARD_CONDITIONAL_RENDERS[conditionalCategory])}`
      );
    }
    if (conditionalCategory === JEWELRY_NO_AML_PROGRAM) {
      // Special case to account for when BOTH of these values are either 50-100k or >100k
      return (
        [FIFTYK_TO_HUNDREDK, OVER_HUNDREDK].includes(watch("businessHasAnnualJewelryPurchase")) &&
        [FIFTYK_TO_HUNDREDK, OVER_HUNDREDK].includes(watch("businessHasAnnualJewelrySales"))
      );
    }
    return true;
  };

  return (
    <>
      <BooleanRadioListField
        control={control}
        name={mainCategoryKey}
        legendText={<div dangerouslySetInnerHTML={{ __html: label }} />} // eslint-disable-line react/no-danger
        onChange={value => {
          if (value) {
            // set value
            setValue(mainCategoryKey, true);
          } else {
            // reset value
            resetSubQuestionValues();
          }
        }}
      />
      {mainCategoryIsSelected &&
        subQuestions
          .flatMap(question => Object.entries(question))
          .filter(([subQuestionCategory]) => shouldRenderConditionalSubQuestion(subQuestionCategory))
          .map(([subQuestionCategory, subQuestion]) => (
            <SubQuestionField
              key={subQuestionCategory}
              subQuestionCategory={subQuestionCategory}
              label={<div dangerouslySetInnerHTML={{ __html: subQuestion }} />} // eslint-disable-line react/no-danger
            />
          ))}
    </>
  );
};

export default RiskyBusinessQuestions;
