import styled from "styled-components";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import { SBB_BRANCH, SBB_CAFE, SBB_WEB } from "../../../constants/applicationChannelTypes";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import Button from "../../shared/Button";
import settingsIcon from "../../../assets/settings.svg";
import getContentReader from "../../../utils/getContentReader";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const StyledButton = styled(Button)`
  margin-top: var(--grv-size-spacing-medium-2);
`;

const StyledGearIcon = styled.img`
  height: 87px;
  aspect-ratio: 1;
  margin-bottom: var(--grv-size-spacing-medium-1);
`;

const ApplicationUnavailable = () => {
  const applicationChannel = useApplicationChannel();
  let title;
  let bundleName;
  let contentId;
  switch (applicationChannel) {
    case SBB_BRANCH:
      title = "Branch Application Unavailable";
      bundleName = "branchPreSubmitErrors";
      contentId = "branchCriticalTechError";
      break;
    case SBB_CAFE: // fall-through
    case SBB_WEB: // fall-through
    default:
      title = "Application Unavailable";
      bundleName = "preSubmitFriction";
      contentId = "webAppUnavailable";
  }

  usePageTitle(title);
  const headingRef = useFocusHeading();
  const content = usePreSubmitTreatment(bundleName, contentId);
  const buttonId = useButtonId("webChannelUnavailableCallToAction");
  if (!content) {
    return <LoadingSpinnerPage loadingSpinnerText="Loading the application..." />;
  }

  const readContent = getContentReader(content);

  return (
    <Page hideVoiceNumber>
      <Grid ref={headingRef}>
        <CenteredRow>
          <Col offset={{ lg: 2 }}>
            <StyledGearIcon alt="" src={settingsIcon} />
          </Col>
        </CenteredRow>
        <Heading
          mainHeading={readContent("headerText")}
          subHeading={readContent("bodyText")}
          extraSpacingAfterMain
        />
        {applicationChannel !== SBB_BRANCH && (
          <CenteredRow>
            <Col offset={{ lg: 2 }}>
              <StyledButton
                id={buttonId}
                onClick={() => window.open(readContent("buttonLinkTarget"), "_blank")}
                gravityType="action"
              >
                {readContent("buttonText")}
              </StyledButton>
            </Col>
          </CenteredRow>
        )}
      </Grid>
    </Page>
  );
};

export default ApplicationUnavailable;
