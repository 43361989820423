import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import useStore from "../../store/store";
import {
  getConfig,
  getComplianceDisclosures,
  getUIDisclosures,
  getBusinessIndustries,
  getCustomerMessages,
  getRiskyAndProhibitedActivities,
  getProducts,
  getUIContentV2,
} from "../../api/sbaoAppEndpoints";
import useApplicationChannel from "./useApplicationChannel";
import { sendErrorToNewRelic } from "../newRelic";
import { isFeatureEnabled } from "../configSelector";

const useLoadPageContent = () => {
  const isLoaded = useRef(false);
  const [addContent, setIndustries, setAllContentLoaded, setContentLoadingError] = useStore(
    store => [
      store.addContent,
      store.setBusinessIndustries,
      store.setAllContentLoaded,
      store.setContentLoadingError,
    ],
    shallow
  );
  const navigate = useNavigate();
  const channel = useApplicationChannel();

  useEffect(() => {
    if (isLoaded.current) {
      return;
    }

    const fetchContent = async () => {
      isLoaded.current = true;

      try {
        const config = await getConfig(); // fetch config first to determine feature flags
        addContent(config);
        if (isFeatureEnabled({ content: [config] }, "newContentServiceEnabled")) {
          const {
            businessIndustryCategoryList: { industries, version: businessIndustriesVersion },
            complianceDisclosures,
            uiDisclosures,
            riskyProhibitedActivities,
            products,
            ...rest
          } = await getUIContentV2();
          setIndustries(industries);
          addContent({ complianceDisclosures });
          addContent({ uiDisclosures });
          addContent({ riskyProhibitedActivitiesQuestions: riskyProhibitedActivities });
          addContent({ products });
          addContent({ businessIndustriesVersion });
          addContent(rest); // remaining top level keys correspond to the remaining customer messages content
        } else {
          const [{ businessIndustriesVersion, ...industries }, ...contentResponses] = await Promise.all([
            getBusinessIndustries(),
            getComplianceDisclosures(),
            getUIDisclosures(),
            getCustomerMessages(),
            getRiskyAndProhibitedActivities(),
          ]);
          setIndustries(industries);
          contentResponses.forEach(addContent);
          addContent({ businessIndustriesVersion });
          addContent(await getProducts({ maxAttempts: 1 }));
        }
        setAllContentLoaded();
      } catch (error) {
        // TODO: Log error here. remove console log
        setContentLoadingError();
        console.log(error);
        sendErrorToNewRelic(error);
        navigate("/application-unavailable");
      }
    };
    // Only fetch content after we understand what channel we are in in order to determine correct behavior on error
    if (channel !== null) {
      fetchContent();
    }
  }, [isLoaded, addContent, setIndustries, setAllContentLoaded, setContentLoadingError, navigate, channel]);
};

export default useLoadPageContent;
