import { SBB_BRANCH, SBB_CAFE } from "../constants/applicationChannelTypes";

// Each object in this array represents a valid A/B test we are conducting
//
// targetTestName: name of the test that we will use for reference wherever the code is conditional on the test result
// mboxName: name of the MBOX that the test is attached to in Adobe Target
// defaultValue: default value to use if a value cannot be obtained from Adobe Target
// acceptableValues: values that are acceptable for the test (to prevent any unexpected values from being used)
// excludedChannels: channels that the test should not be run on
export const validReceivableTargetTests = [
  {
    targetTestName: "voiceNumberTreatment",
    mboxName: "sbbdao-mbox-voice-number",
    defaultValue: "voiceNumberDisabled",
    acceptableValues: ["voiceNumberEnabled", "voiceNumberDisabled"],
    excludedChannels: [],
  },
  {
    targetTestName: "signInContentTreatment",
    mboxName: "sbbdao-mbox-sign-in-content",
    defaultValue: "normalContent",
    acceptableValues: ["normalContent", "efficientContent"],
    excludedChannels: [SBB_BRANCH, SBB_CAFE],
  },
];

// List of the MBOXes that the A/B tests are attached to (can have more than one active test per MBOX, but only one test result will be returned per MBOX based on prioritization)
export const validMboxNames = validReceivableTargetTests.map(({ mboxName }) => mboxName);

export const trackAdobeTargetEventForMbox = (mboxEvent, mboxName) => {
  window.adobe?.target?.trackEvent({
    mbox: mboxName,
    params: {
      ...mboxEvent,
    },
  });
};
