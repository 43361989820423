import { useMemo } from "react";
import styled from "styled-components";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import { MainHeading } from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Page from "../../shared/Page";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import faqIcon from "../../../assets/FAQ.svg";
import { useDecisionPlatformTreatment } from "../../../utils/hooks/usePostSubmitTreatment";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store";

const Icon = styled.img`
  height: var(--grv-size-spacing-xlarge-5);
  aspect-ratio: 1;
`;

const ManualReviewFAQ = ({
  iconSrc = faqIcon,
  headingText = "Frequently Asked Questions (FAQs)",
  questionOverride = false,
}) => {
  usePreventNavigation();
  usePageTitle("Manual Review FAQ");
  const ref = useFocusHeading();
  const yellowPathBundle = useDecisionPlatformTreatment("inProcess", "yellowPathFAQ");
  const decisionManualReviewBundle = useDecisionPlatformTreatment("inProcess", "maoManualReview");
  const readContent = getContentReader(decisionManualReviewBundle);

  const applicantEmailAddress = useStore(state => state.applicantEmailAddress);
  const secondFAQBodyText = readContent("bodyText")?.replaceAll(
    "{{PERSONAL_EMAIL_ADDRESS}}",
    applicantEmailAddress
  );

  const numFAQ = useMemo(() => {
    if (!yellowPathBundle) {
      return 0;
    }

    // Count the number of questions/answers in the bundle
    // Could theoretically assume there is only 1 non-question/answer key,
    // but since this is chariot content it is probably better to check explicitly.
    // Content keys are of the form questionNText/answerNText, with N starting from 1.
    // This will allow product to add and remove questions without code changes.
    const numQuestions = Object.keys(yellowPathBundle).filter(k => k.match(/^question\d+Text$/)).length;
    const numAnswers = Object.keys(yellowPathBundle).filter(k => k.match(/^answer\d+Text$/)).length;
    // Since we need both a question and an answer, just set this to be the min
    return Math.min(numQuestions, numAnswers);
  }, [yellowPathBundle]);

  if (!yellowPathBundle || (questionOverride && !decisionManualReviewBundle)) {
    return <LoadingSpinnerPage />;
  }

  const displayQuestions = questionOverride
    ? { ...yellowPathBundle, answer1Text: secondFAQBodyText }
    : yellowPathBundle;

  return (
    <Page hideVoiceNumber>
      <Grid ref={ref}>
        <CenteredRow className="grv-margin__bottom--medium-1">
          <Col offset={{ lg: 2 }}>
            <Icon alt="" src={iconSrc} />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8}>
            <MainHeading>{headingText}</MainHeading>
          </Col>
        </CenteredRow>
      </Grid>
      {Array.from(Array(numFAQ).keys())
        .map(i => ({
          questionText: displayQuestions[`question${i + 1}Text`],
          answerText: displayQuestions[`answer${i + 1}Text`],
          // not only does this trick eslint, it's actually probably *more* correct,
          // since theoretically a question/answer might be missing and get filtered
          key: `faqItem${i + 1}`,
        }))
        .filter(({ questionText, answerText }) => questionText && answerText)
        .map(({ questionText, answerText, key }) => (
          <Grid className="grv-margin__top--medium-2" key={key}>
            <CenteredRow>
              <Col lg={8} className="grv-weight--semibold grv-text--medium-2">
                {questionText}
              </Col>
            </CenteredRow>
            <CenteredRow>
              <Col lg={8} dangerouslySetInnerHTML={{ __html: answerText }} />
            </CenteredRow>
          </Grid>
        ))}
    </Page>
  );
};

export default ManualReviewFAQ;
